import {useState, useEffect} from "react";
import {prod} from "../../env";
import {CloseOutlined, EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import general from "../../general";
import {useInterval, useToggle} from "ahooks";
import {Link, useHistory} from "react-router-dom";
import Dialog from "../../lib/Dialog";

export default props => {
  const history = useHistory();
  const [data, setData] = useState(!prod ? {mobile: '12345678901', code: '1234', newpassword: '12345678901'} : {});
  const [focus, setFocus] = useState('');
  const [newpassword, {toggle: toggleNewpassword}] = useToggle(true);

  const [coding, setCoding] = useState(false);
  const [codeTime, setCodeTime] = useState(0);
  const [areaNumData, setAreaNumData] = useState([]);

  useEffect(() => {
    setAreaNumData(general.areaNumber);
  }, [props.active]);

  useInterval(() => {
    if (codeTime) {
      setCodeTime(codeTime - 1);
    }
  }, 1000);
  const code = () => {
    if (!data.mobile) {
      Dialog.error('请输入手机号');
      return;
    }
    if (coding || codeTime) {
      return;
    }
    setCoding(true);
    Dialog.loading();
    general.axios.post('/base/tool/getVerifyCode', {
      mobile: (areaNumData?areaNumData:'86') + '-' + data.mobile,
      code: 'code',
      type: '03',
    })
        .finally(() => {
          setCoding(false);
          Dialog.close();
        })
        .then(response => {
          let result = response.data;
          if (!result.status.succeed) {
            Dialog.error(result.status.error_desc);
          } else {
            setCodeTime(60);
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  const [submitting, setSubmitting] = useState(false);
  const submit = () => {
    if (submitting) {
      return;
    }
    if (!data.mobile) {
      Dialog.error('请输入手机号');
      return;
    }
    if (!data.code) {
      Dialog.error('请输入验证码');
      return;
    }
    if (!data.newpassword) {
      Dialog.error('请输入新密码');
      return;
    }
    setSubmitting(true);
    Dialog.loading();
    general.axios.post('/user/user/forgetPwd', data)
        .finally(() => {
          setSubmitting(false);
          Dialog.close();
        })
        .then(response => {
          let {status} = response.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            Dialog.success('操作成功', () => history.goBack());
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  return <div className="account-forget">
    <div className="input-item">
      <div className="label">
        {/* 手机号 */}
        <div className="area-num-text">
          <img src="/resources/account/jia.png" />
          <Link to="/account/country" className="value">
            {areaNumData ? areaNumData : '86'}
          </Link>
          <img src="/resources/account/sel-down.png" />
        </div>
      </div>
      <div className="input">
        {/* <Link to="/account/country" className="value">
            {areaNumData ? '+' + areaNumData : '+86'}
          </Link> */}
        <input type="text" value={data.mobile} placeholder="请输入手机号" autoComplete={false} onFocus={e => setFocus('mobile')} onChange={e => setData({...data, mobile: e.target.value})}/>
        {focus === 'mobile' && data.mobile ? <CloseOutlined className="assist close" onClick={() => setData({...data, mobile: ''})}/> : null}
      </div>
    </div>
    <div className="input-item">
      <div className="label">验证码</div>
      <div className="input">
        <input type="tel" value={data.code} placeholder="请输入验证码" autoComplete={false} onFocus={e => setFocus('code')} onChange={e => setData({...data, code: e.target.value})}/>
        {focus === 'code' && data.code ? <CloseOutlined className="assist close" onClick={() => setData({...data, code: ''})}/> : null}
      </div>
      <div className={'btn ' + (coding || codeTime ? 'disable' : '')} onClick={code}>
        {coding ? '发送中' : (codeTime ? (codeTime + '秒后重发') : '发送验证码')}
      </div>
    </div>
    <div className="input-item">
      <div className="label">新密码</div>
      <div className="input">
        <input name="newpassword" type={newpassword ? 'password' : 'text'} value={data.newpassword} placeholder="请输入新密码" onFocus={e => setFocus('newpassword')} onChange={e => setData({...data, newpassword: e.target.value})}/>
        <div className="assist password" onClick={() => toggleNewpassword()}>
          {newpassword ? <EyeInvisibleOutlined/> : <EyeOutlined/>}
        </div>
        {focus === 'newpassword' && data.newpassword ? <CloseOutlined className="assist close" onClick={() => setData(data => ({...data, newpassword: ''}))}/> : null}
      </div>
    </div>
    <div className={'operate ' + (submitting ? 'disable' : '')} onClick={submit}>提交</div>
  </div>;
};