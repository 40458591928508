import {createSlice} from '@reduxjs/toolkit';

export const reducer = createSlice({
  name: 'name',
  initialState: {},
  reducers: {
    setState(state, action) {
      for (const key in action.payload) {
        state[key] = action.payload[key];
      }
    }
  },
});

export const {setState} = reducer.actions;

export const state = (state) => state.reducer;

export default reducer.reducer;
