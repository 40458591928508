import {useState, useEffect, useCallback, useRef} from "react";
import general from "../../general";
import Dialog from "../../lib/Dialog";
import {useHistory} from "react-router-dom";
import {useUpdateEffect, useScroll, useThrottleEffect} from "ahooks";
import $ from "jquery";

export default props => {
    const history = useHistory();
    const [datas, setDates] = useState([]);
    const [areaKey, setAreaKey] = useState([]);

    const load = () => {
        Dialog.loading();
        general.axios.get('/getAreaList')
        .finally(() => {
            Dialog.close();
        })
        .then(res => {
            let {status, data} = res.data;
            if (!status.succeed) {
                Dialog.error(status.error_desc);
                return;
            }
            setDates(data)
            
            let arr = [];
            for(let i = 0; i < data.length; i++){
                arr.push(data[i].key);
            }

            setAreaKey(arr);
        })
        .catch(err => {
            Dialog.error(err.message);
        });
    }

    useEffect(() => {
        load();
    }, []);

    //标签
    const [tag, setTag] = useState(0);

    const scroll = useScroll();
    const [mode, setMode] = useState();
    const timesRef = useRef(null);
    const [timeEl, setTimeEl] = useState();
    const timeRef = useCallback(node => {
    if (node) {
        setTimeEl(node);
    }
    }, []);
    const groupsRef = useRef(null);
    const [groupEl, setGroupEl] = useState();
    const groupRef = useCallback(node => {
        if (node) {
            setGroupEl(node);
        }
    }, []);

    //定位
    useEffect(() => {
        if (timesRef.current && timeEl && groupEl) {
            if (mode !== 'scroll') {
                // $('html,body').animate({scrollTop: groupEl.offsetTop - (timesRef.current.offsetTop + timesRef.current.offsetHeight)}, 250);
                // $('html,body').animate({scrollTop: groupEl.offsetTop - (timesRef.current.offsetTop)}, 250);
                $('html,body').animate({scrollTop: groupEl.offsetTop}, 250);
            }
        }
    }, [tag, mode, timesRef, timeEl, groupEl]);

    return <div className="country-index">
        <div className="sel-letters"  ref={timesRef}>
            <ul>
                {areaKey.map((item, index) => 
                    <li
                    ref={index === tag ? timeRef : null}
                    onClick={() => {
                        setTag(index);
                        setMode('direct');
                    }}
                    >{item}</li>
                )}
            </ul>
        </div>

        <div className="sel-area-main">
            <div className="">
                <div className="letters_control_list" ref={groupsRef}>
                    {datas.map((item, index) => <div>
                        <div className="letters_control" ref={index === tag ? groupRef : null}>
                            <div className="letters" >{item.key}</div>
                            <ul>
                                {item.data.map(area => <li onClick={()=> {general.areaNumber = area.phoneCode;history.goBack();}}>
                                    <div className="area-text">
                                        {area.countryName}
                                    </div>
                                    <div className="area-country-num">
                                        <img src="/resources/account/jia.png" />
                                        {area.phoneCode}
                                    </div>
                                </li>)}
                            </ul>
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    </div>
}